<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="getMenu" />
  </div>
</template>

<script>
//console.log('request menu ...');
//import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    const navMenuItems=ref([]);

    navMenuItems.value=store.state.app.menu;
    //console.log(store.state.app.menu)
    return {
      navMenuItems,
    }
  },
  computed:{
    getMenu(){
      return store.state.app.menu
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
